<template>
  <div>
    <Popup_1
      :activeContent="activeContent"
      v-if="activeContent.type == 2"
      @close="$emit('close')"
    ></Popup_1>
    <Popup_2
      :activeContent="activeContent"
      v-if="activeContent.type == 1"
      @close="$emit('close')"
    ></Popup_2>
    <Popup_3
      :activeContent="activeContent"
      v-if="activeContent.type == 3"
      @close="$emit('close')"
    ></Popup_3>
    <Popup_4
      :activeContent="activeContent"
      v-if="activeContent.type == 4"
      @close="$emit('close')"
    ></Popup_4>
    <Popup_5
      :activeContent="activeContent"
      v-if="activeContent.type == 5"
      @close="$emit('close')"
    ></Popup_5>
    <Popup_8
      :activeContent="activeContent"
      v-if="activeContent.type == 6"
      @close="$emit('close')"
    ></Popup_8>
    <Popup_4_1
      :activeContent="activeContent"
      v-if="activeContent.type == 7"
      @close="$emit('close')"
    ></Popup_4_1>
    <Popup_9
      :activeContent="activeContent"
      v-if="activeContent.type == 8"
      @close="$emit('close')"
    ></Popup_9>
    <Popup_10
      :activeContent="activeContent"
      v-if="activeContent.type == 9"
      @close="$emit('close')"
    ></Popup_10>
    <Popup_11
      :activeContent="activeContent"
      v-if="activeContent.type == 10"
      @close="$emit('close')"
    ></Popup_11>
    <Popup_12
      :activeContent="activeContent"
      v-if="activeContent.type == 12"
      @close="$emit('close')"
    ></Popup_12>
    <Popup_13
      :activeContent="activeContent"
      v-if="activeContent.type == 13"
      @close="$emit('close')"
    ></Popup_13>
    <Popup_14
      :activeContent="activeContent"
      v-if="activeContent.type == 14"
      @close="$emit('close')"
    ></Popup_14>
    <Popup_15
      :activeContent="activeContent"
      v-if="activeContent.type == 15"
      @close="$emit('close')"
    ></Popup_15>
    <Popup_16
      :activeContent="activeContent"
      v-if="activeContent.type == 16"
      @close="$emit('close')"
    ></Popup_16>
    <Popup_17
      :activeContent="activeContent"
      v-if="activeContent.type == 17"
      @close="$emit('close')"
    ></Popup_17>
  </div>
</template>

<script>
import Popup_1 from "@/components/Popup_1.vue";
import Popup_2 from "@/components/Popup_5.vue";
import Popup_3 from "@/components/Popup_3.vue";
import Popup_4 from "@/components/Popup_2.vue";
import Popup_5 from "@/components/Popup_7.vue";
import Popup_8 from "@/components/Popup_8.vue";
import Popup_9 from "@/components/Popup_9.vue";
import Popup_4_1 from "@/components/Popup_2_1.vue";
import Popup_10 from "@/components/Popup_1_1.vue";
import Popup_11 from "@/components/Popup_1_2.vue";
import Popup_12 from "@/components/Popup_10.vue";
import Popup_13 from "@/components/Popup_12.vue";
import Popup_14 from "@/components/Popup_13.vue";
import Popup_15 from "@/components/Popup_14.vue";
import Popup_16 from "@/components/Popup_15.vue";
import Popup_17 from "@/components/Popup_17.vue";
export default {
  name: "Modals",
  props: {
    activeContent: {
      type: Object,
      required: true,
    },
  },
  components: {
    Popup_1,
    Popup_2,
    Popup_3,
    Popup_4,
    Popup_5,
    Popup_8,
    Popup_9,
    Popup_4_1,
    Popup_10,
    Popup_11,
    Popup_12,
    Popup_13,
    Popup_14,
    Popup_15,
    Popup_16,
    Popup_17,
  },
};
</script>
